export default {
    "HOMEPAGE_TITLE": "Unlock earning potential: Maximize your returns with <span>{{stakonomics}}</span>",
    "TITLE_CONTENT": "Start your crypto staking journey today and watch your investments flourish. It's time to stake your claim in the crypto revolution!",
    "HOME_BTN1": "Stake Now",
    "HOME_BTN2": "Register Now",
    "COUNT1": "All Participants",
    "COUNT2": "Total Earned",
    "COUNT3": "BTC 24H Change",
    "HEADER1": "How <span>{{stakonomics}}</span> works",
    "TITLE11": "Stake Your Cryptocurrency",
    "CONTENT11": "Choose a cryptocurrency that supports staking, acquire it, and transfer it to a compatible staking platform or wallet.",
    "TITLE12": "Participate in Stakonomics",
    "CONTENT12": "Your staked cryptocurrency helps secure the blockchain network and supports its operations. Your involvement in network activities earns you staking rewards based on your stake's size and the network's rules.",
    "TITLE13": "Manage and Monitor",
    "CONTENT13": "Regularly review your staking performance, track your rewards, and stay informed about any changes in the network. Adjust your strategy as necessary to make the most of your staking experience.",
    "HEADER2": "Earn up to <span>{{stakonomics}}</span> On your assets",
    "TITLE21": "Passive Income with Staking",
    "CONTENT21": "Exploring the Potential of Earning Passively through Crypto Staking",
    "TITLE22": "Maximizing Staking Returns",
    "CONTENT22": "Strategies to Optimize Your Earnings in Crypto Staking",
    "TITLE23": "Understanding Staking Rewards",
    "CONTENT23": "How Staking Yields Profits in the World of Cryptocurrency",
    "STAKECAL_TITLE": "See what you can Earn <span>{{Staking}}</span>",
    "ASSET": "Asset",
    "AMOUNT": "Amount",
    "AMOUNT_PLACEHOLDER": "Enter Amount",
    "STAKING_METHOD": "Staking Method",
    "DEPOSIT_TERM": "Deposit Term",
    "FLEXIBLE": "Flexible",
    "FIXED": "Fixed",
    "STAKE_PERIOD": "In 12 months you will have total of",
    "IN": "In",
    "YOU_WILL": "you will have a total of",
    "FREQUENTLY_ASKED_QUESTIONS": "Frequently Asked  <span>{{Questions}}</span>",
    "REGISTER": "Register",
    "LOGIN": "Login",
    "SIGN_UP": "Sign Up",
    "SIGN_IN": "Sign In",
    "FIRST_NAME": "First Name",
    "FIRST_NAME_PLACEHOLDER": "Enter First Name",
    "FIRST_NAME_REQUIRED": "First name field is required",
    "LAST_NAME": "Last Name",
    "LAST_NAME_PLACEHOLDER": "Enter Last Name",
    "LAST_NAME_REQUIRED": "Last name field is required",
    "EMAIL": "Email Address",
    "EMAIL_PLACEHOLDER": "Enter Email Address",
    "EMAIL_REQUIRED": "Email field is required",
    "EMAIL_INVALID": "Invalid email",
    "EMAIL_NOT_EXISTS": "Email does not exists",
    "EMAIL_EXISTS": "Email already exists",
    "PHONE_NUMBER": "Phone Number",
    "PHONE_PLACEHOLDER": "Enter Phone Number",
    "PHONE_NUMBER_REQUIRED": "Phone number field is required",
    "PHONE_NUMBER_INVALID": "Invalid phone number",
    "PHONE_NUMBER_EXISTS": "Phone number already exists",
    "PASSWORD": "Password",
    "PASSWORD_PLACEHOLDER": "Enter Password",
    "PASSWORD_REQUIRED": "Password field is required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "PASSWORD_MIN_MAX": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18",
    "CONFIRM_PASSWORD": "Confirm Password",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Enter Confirm Password",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password field is required",
    "CONFIRM_PASSWORD_MISMATCH": "Password and confirm password must be same",
    "TERMS_REQUIRED": "Terms & policy field is required",
    "ALREADY_HAVE_ACCOUNT": "Already have an account",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "REGISTER_CHECKBOX": "I Understand <Link to='/terms'>{{value1}}</Link> and <Link1 to='/privacy'>{{value2}}</Link1>",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "Enter OTP",
    "RESENT_OTP": "Resend Verification Code",
    "OTP_VALIDUPTO": "OTP valid up to",
    "OTP_REQUIRED": "OTP field is required",
    "OTPSTATUS_REQUIRED": "OTP status is required",
    "INVALID_OTPSTATUS": "Invalid OTP status",
    "OTP_SENT_SUCCESS": "OTP sent to your email address, please enter a OTP",
    "EXPIRED_OTP": "Expired OTP",
    "INVALID_OTP": "Invalid OTP",
    "SEC": "Sec",
    "REGISTER_SUCCESS": "Your account has been successfully registered. Thank you!",
    "SOMETHING_WRONG": "Something went wrong",
    "ERROR_ON_SERVER": "Error on server",
    "ERROR_OCCURED": "Error occured",
    "EMAIL_UNVERIFIED": "Your account still not activated",
    "ACCOUNT_DEACTIVATE": "Your account deactivated by admin, please contact admin",
    "LOGIN_SUCCESS": "Logged In successfully",
    "LOGOUT_SUCCESS": "Logout Successfully!",
    "FORGOT_PASSWORD": "Forgot password",
    "FORGOT_PASSWORD_CONTENT": "Reset Password link has been send to your registered Email Address",
    "FORGOT_SUCCESS": "Confirmation link has been sent to your registered mail",
    "AUTH_TOKEN_REQUIRED": "Auth token field is required",
    "INVALID_TOKEN": "Invalid token",
    "RESET_PASSWORD": "Reset Password",
    "NEW_PASSWORD": "New Password",
    "NEW_PASSWORD_PLACEHOLDER": "Enter New Password",
    "NEW_PASSWORD_REQUIRED": "New password field is required",
    "NEW_CONFIRM_MISMATCH": "New and confirm password must be same",
    "LING_EXPIRED": "Your link was expired",
    "INVALID_LINK": "Invalid link",
    "USER_NOT_FOUND": "User not found",
    "VERIFICATION_SUCCESS": "Your verification process completed, you can now change password",
    "RESET_PASSWORD_SUCCESS": "Password update successfully",
    "CHANGE_PASSWORD": "Change Password",
    "OLD_PASSWORD": "Old Password",
    "OLD_PASSWORD_PLACEHOLDER": "Enter Old Password",
    "OLD_PASSWORD_REQUIRED": "Old password field is required",
    "INCORRECT_OLDPASSWORD": "Incorrect old password",
    "PASSWORD_SAME": "New password can't be a old password",
    "CHANGE_PASSWORD_SUCCESS": "Password changed successfully",
    "CHANGE": "Change",
    "LAST_LOGIN": "Last login",
    "EDIT_PROFILE": "Edit Profile",
    "UPDATE_PROFILE": "Update Profile",
    "PROFILE": "Profile Picture (Optional)",
    "INVALID_PROFILE": "Invalid profile format",
    "TOO_LARGE": "Too large",
    "PROFILE_RESTRICTION": "Max. upload file size 2MB (Jpeg, Png, Jpg only).",
    "ADDRESS": "Address",
    "ADDRESS_PLACEHOLDER": "Enter Address",
    "ADDRESS_REQUIRED": "Address field is required",
    "CITY": "City",
    "CITY_PLACEHOLDER": "Enter City",
    "CITY_REQUIRED": "City field is required",
    "POSTAL_CODE": "Postal / Zip Code",
    "POSTAL_CODE_PLACEHOLDER": "Enter Postal / Zip Code",
    "POSTAL_CODE_REQUIRED": "Postal / Zip Code field is required",
    "COUNTRY": "Country",
    "COUNTRY_PLACEHOLDER": "Enter Country",
    "COUNTRY_REQUIRED": "Country field is required",
    "UPLOAD_IMAGE": "Upload image cannot more then 2MB!",
    "CANCEL": "Cancel",
    "UPDATE": "Update",
    "SUBMIT": "Submit",
    "PROFILE_SUCCESS": "Profile update successfully",
    "LOGIN_ACTIVITY": "Login Activity",
    "DATE": "Date",
    "BROWSER": "Browser",
    "STATUS": "Status",
    "FAILED": "Failed",
    "SUCCESS": "Success",
    "MY_ACCOUNT": "My Account",
    "ACCOUNT_SETTINGS": "Account settings",
    "LOGOUT": "Logout",
    "NOTIFICATION": "Notifications",
    "NO_NOTIFICATION": "No Notification!",
    "UPDATE_SUCCESS": "Update successfully",

    "SUPPORT_TICKET": "Support Ticket",
    "SUBMIT_REQUEST": "Submit a Request",
    "TICKET_FOR": "Ticket For",
    "SELECT_CATEGORY": "Select Category",
    "DESCRIPTION": "Description",
    "ENTER_MESSAGE": "Enter Message",
    "SUPPORT_DESCRIPTION": "Please enter the details of your request. A member of our support staff will respond as soon as possible.",
    "REQUIRED": "Required",
    "DOCUMENT_UPLOAD": "Upload Document (Optional)",
    "UPLOAD": "Upload",
    "UPLOAD_SIZE_RESTRICT": "Max. upload file size 1MB (Jpeg, Png, Pdf ,Jpg only).",
    "UPLOAD_ERROR": "Upload image cannot more then 1MB!",
    "MESSAGE_REQUIRED": "Message field is required",
    "SUBJECT_REQUIRED": "Subject field is required",
    "UPLOAD_ERROR2": "Please choose with the known file types jpg, jpeg, png, pdf, or mp4.",
    "TICKET_RAISED_SUCCESS": "Support ticket raised successfully",
    "TICKET_ALREADY_CLOSED": "Ticket already closed",
    "MESSAGE_SENT_SUCCESS": "Your message has been sent successfully",
    "SUPPORT_TICKET_DETAILS": "Support Ticket Details",
    "TICKET_CLOSED_SUCCESS": "Ticket Closed Successfully",
    "TICKET_ID": "Ticket ID",
    "CREATES_ON": "Creates on",
    "VIEW": "View File",
    "SUPPORT_TEAM": "Support Team",
    "OPEN": "Open",
    "CLOSED": "Closed",
    "ALL": "All",
    "SEARCH": "Search",
    "CLOSE_TICKET": "Close Ticket",
    "SUB": "Sub",
    "TICKET_NOT_FOUND": "No Tickets found",

    "WALLET": "Wallet",
    "TOTAL_BALANCE": "Total Balance",
    "BALANCE": "Balance",
    "ACTION": "Action",
    "DEPOSIT": "Deposit",
    "WITHDRAW": "Withdraw",
    "EXCHANGE": "Exchange",
    "EXCHANGE_ACCESS": "Exchange Access Blocked",
    "STAKE": "Stake",
    "STAKE_ACCESS": "Stake Access Blocked",
    "TRADE_ACCESS": "Trade Access Blocked",
    "LIVE_EVENT_ACCESS": "Live Events Access Blocked",
    "HEDGE_ACCESS": "Hedge Fund Access Blocked",
    "HIDE_ZERO_BALANCES": "Hide Zero Balances",



    "DEPOSIT_TERMS": "Deposit Terms",
    "MINIMUM_DEPOSIT_AMOUNT": "Minimum Deposit Amount: ",
    "INVALID_IMAGE": "Invalid image",
    "MAINTENANCE_ERROR": "Maintenance in process try after some time!",
    "DEPOSIT_DESCRIPTION": "Deposits Less Than The Minimum Amount Will Not Be Accepted And Refunds Are Not Allowed",
    "DEPOSIT_NOTES1": "Send only {{CURRENCY_SYMBOL}} To This Wallet Address.",
    "DEPOSIT_NOTES2": "Sending Coins Or Tokens Other Than {{CURRENCY_SYMBOL}} To This Wallet May Result In The Total Loss Of Your Deposit",
    "DEPOSIT_NOTES3": "Please Upload A Screenshot Or A Hash Of Your Transaction In Order For Us To Approve Your Transaction",
    "DEPOSIT_CARD": "Deposit With Card",
    "DEPOSIT_ADDRESS": "Deposit Address",
    "MEMO_ADDRESS": "Memo Address",
    "DEPOSIT_IMAGE_RESTRICTION": "Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).",
    "DEPOSIT_AMOUNT_ERROR": "Please enter the deposit amount is greater than or equal to {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "Deposit request sent successfully",
    "ASSET_ERROR": "User asset field is required",
    "COPIED": "Copied!",



    "WITHDRAW_TERMS": "Withdraw Terms",
    "MINIMUM_WITHDRAW_AMOUNT": "The Minimum Withdrawal amount is: ",
    "TERMS1": "Maximum Withdraw :",
    "TERMS2": "Withdraw Fee :",
    "RECEIVER_ADDRESS": "Receiver Address",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Please enter withdrawl address here",
    "RECEIVER_ADDRESS_REQUIRED": "Receiver address field is required",
    "WITHDRAW_AMOUNT": "Withdrawal Amount",
    "WITHDRAW_AMOUNT_ERROR": "Please enter withdraw amount greater than or equal to {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "Minimum withdraw amount must be greater than or equal to {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "Withdraw amount must be less than or equal to {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "Withdraw request sent successfully",
    "INSUFFICIENT_WITHDRAW": "Withdraw amount too low",
    "WITHDRAW_OTP": "An OTP has been successfully sent to your email address. Please check your inbox and use the provided code to proceed",

    "FROM_CURRENCY": "From Currency",
    "TO_CURRENCY": 'To Currency',
    "TO_CURRENCY_REQUIRED": "To currency field is required",
    "SELECT_CURRENCY": "Select Currency",
    "INVALID_USER": "Invalid user",
    'INSUFFICIENT_BALANCE': "Insufficient balance",
    "TRANSFER_SUCCESS": "Transfered successfully",



    "TRANSACTION": "Transactions",
    "DATE": "Date",
    "COIN": "Coin",
    "USER_ID": "User Id",
    "TYPE": "Type",
    "REASON": "Reason",
    "PENDING": "Pending",
    "COMPLETED": "Completed",
    "REJECTED": "Rejected",
    "COIN_DEPOSIT": "Coin_deposit",
    "COIN_WITHDRAW": "Coin_withdraw",
    "COIN_TRANSFER": "Coin-Transfer",
    "PROFIT": "Profit",
    "ROWS_PER_PAGE": "Rows per page",
    "OF": "of",

    "STAKING": "Staking",
    "AVAILABLE_BALANCE": "Available Balance",
    "AVAILABLE_BALANCE_USDT": "Available Balance USDT",
    "APY": "APY",
    "MAX": "Max",
    "INTEREST": "Interest",
    "MINIMUM_STAKE": "Minimum Stake",
    "MAXIMUM_STAKE": "Maximum Stake",
    "WITHDRAWAL_TERM": "Withdrawal Term",
    "REDEMPTION_PERIOD": "Redemption Period",
    "DURATION": "Duration (Days)",
    "CONFIRM": "Confirm",
    "STAKING_PERIOD": "Staking Period",
    "LOCKED_FOR": "Locked For ",
    "AMOUNT_REQUIRED": "Amount field is required",
    "VALID_AMOUNT": "Please enter a valid amount",
    "AMOUNT_CAN'T_ZERO": "Amount must be greater than zero",
    "MINIMUM_AMOUNT": "Minimum amount must be greater than or equal to {{AMOUNT}}",
    "MAXIMUM_AMOUNT": "Amount must be less than or equal to {{AMOUNT}}",
    "INVALID_STAKING": "Invalid staking",
    "INVALID_CURRENCY": "Invalid currency",
    "INVALID_ASSET": "Invalid asset",
    "DEACTIVE": "Deactive",
    "NO_RECORD": "No record",
    "INSUFFICIENT_ASSET": "There is not enough asset in your balance",
    "STAKING_SUCCESS": "Staking order added successfully",
    "DURATION_ERROR": "Kindly choose the duration days",
    "ALREADY_CANCELED": "Already cancelled",
    "STAKING_HISTORY": "Staking History",
    "TOTAL_EARNED_REDEMPTION": "Total Earned Redemption",
    "INTEREST_PAID": "Interest Paid",
    "ESTIMATED_APY_INTEREST": "Estimated APY Interest",
    "ESTIMATED_TOTAL_INTEREST": "Estimated Total Interest",
    "ESTIMATED_TOTAL_REDEMPTION": "Estimated Total Redemption",
    "REMAINING_DAYS": "Remaining Day's",
    "RELEASE": "Release",
    "INTEREST_EARNED": "Interest Earned",
    "INTEREST_IN": "Interest in ($)",
    "STAKE_TYPE": "Stake Type",
    "SUBSCRIPTION": "Subscription",
    "REDEMPTION_HISTORY": "Redemption History",
    "THERE_NO_RECORD": "There are no records to display",
    "RELEASE_STAKE": "Are you sure to cancel this order",
    "OK": 'OK',
    "RELEASE_SUCCESS": "Released successfully",


    "TRADE": "Trade",
    "PRO_TRADE": "PRO TRADING",
    "BASIC": "Basic",
    "LOADING": "Loading",
    "PNL": "PNL",
    "CASH_BALANCE": "Cash Balance",
    "EQUITY": "Equity",
    "USED_MARGIN": "Used Margin",
    "AVAILABLE_MARGIN": "Available Margin",
    "CRYPTO": "Crypto",
    "CURRENCIES": "Currencies",
    "STOCKS": "Stocks",
    "INDICES": "Indices",
    "COMMODITIES": "Commodities",
    "BUY": "Buy",
    "SELL": "Sell",
    "QUANTITY": "Quantity",
    "QUANTITY_PLACEHOLDER": "Enter Quantity",
    "QUANTITY_REQUIRED": "Quantity field is required",
    "QUANTITY_ERROR": "Quantity only numeric value",
    "QUANTITY_GRATER": "Quantity must be greater than zero",
    "SIDE_REQUIRED": "Side field is Required",
    "INVALID_SIDE": "Invalid side",
    "PAIR_REQUIRED": "Pair field is Required",
    "TRADE_SIZE": "Trade Size",
    "PIP_VALUE": "Pip Value",
    "ORDER_BOOK": "Order Book",
    "OPEN_POSITIONS": "Open Positions",
    "CLOSED_POSITIONS": "Closed Positions",
    "CLOSE_ALL_TRADES": "Close All Trades",
    "PAIR_NAME": "Pair Name",
    "BUYSELL": "Buy/Sell",
    "ENTRY_PRICE": "Entry Price",
    "OPEN_TIME": "Open Time",
    "CLOSE_PRICE": "Close Price",
    "CLOSE_TIME": "Close Time",
    "CURRENT_PRICE": "Current Price",
    "LEVERAGE": "Leverage",
    "PNL_CLOSE": "PNL/Close",
    "TP": "TP",
    "TAKE_PROFIT": "Take Profit",
    "STOP_LOSS": "Stop Loss",
    "SL": "SL",
    'TP_PRICE': "Take Profit At Price",
    "SP_PRICE": "Stop Loss At Price",
    "TAKE_PROFIT_PLACEHOLDER": "Enter take profit",
    "STOP_LOSS_PLACEHOLDER": "Enter stop loss",
    "TAKE_PROFIT_REQUIRED": "Take Profit field is required",
    "TAKE_PROFIT_GREATER": "Take profit must be greater than market price",
    "TAKE_PROFIT_LESSER": "Take profit must be lesser than market price",
    "STOP_LOSS_REQUIRED": "Stop loss field is required",
    "STOP_LOSS_GREATER": "Stop loss must be lesser than market price",
    "STOP_LOSS_LESSER": "Stop loss must be greater than market price",
    "POSITION_NOT_FOUND": "Position not found",
    "TAKE_PROFIT_SUCCESS": "Take Profit update successfully",
    "STOP_LOSS_SUCCESS": "Stop loss update successfully",
    "CLOSED_USER": "Closed By User",
    "LOW_MARGIN": "Low Margin",
    "CLOSE_ORDER": "Closing the order",
    "CLOSE_CONFIRM": "Are you sure to close this order",
    "TRADE_ALREADY_CLOSED": "Your order already closed",
    "INVALID_SIDE": "Invalid side",
    "INVALID_PAIR": "Invalid pair",
    "QUANTITY_MINIMUM_ERROR": "Quantity of contract must not be lesser than {{AMOUNT}}",
    "QUANTITY_MAXIMUM_ERROR": "Quantity of contract must not be higher than {{AMOUNT}}",
    "INSUFFICIENT_TRADE_BALANCE": "Due to insufficient balance order cannot be placed",
    "ORDER_PLACE_SUCCESS": "Your order placed successfully",
    "NO_DATA": "No data",
    "ORDER_CLOSE_SUCCESS": "Your order closed successfully",
    "CLOSED_BY_USER": "Closed By User",
    "LOW_MARGIN": "Low Margin",

    "LIVE_EVENTS": 'Live Events',
    "NO_LIVE_EVENTS": "There are no live events to display",
    "LIVE_ERROR": "Your browser does not support the video tag",

    "HEDGE_FUND": "Hedge Fund",
    "TOTAL_HEDGE_FUND": "Total Hedge Fund Portfolio Value",
    "PORTFOLIO_PERFORMANCE": "Portfolio Performance",
    "PORTFOLIO_ALLOCATION": "Portfolio Allocation",
    "NAME": "Name",
    "PRICE": "Price",
    "24_CHANGE": "24H Change",
    "24_HIGH": "24 High",
    "24_LOW": "24 Low",
    "TOTAL": "Total",
    "DAY": "Day",
    "DAYS": "Days",
    "WEEK": "Week",
    "MONTH": "Month",
    "YEAR": "Year",

    "STAKED_BALANCE": "Staked Balance",
    "ACCOUNT_BALANCE": "Account Balance",
    "TOTAL_PL": "Total P&L",

    "INSTRUMENTS": "Instruments",
    "FOREX": "Forex",
    "TRADING": "Trading",
    "PLATFORM": "Platform",
    "OPEN_ACCOUNT": "Open Account",
    "MT5_PLATFORM": "Mt5 Platform",
    "WEBTRADER_PLATFORM": "Webtrader Platform",
    "MOBILE_PLATFORM": "Mobile Platform",
    "FX_PAYMENTS": "FX Payments",
    "ACCOUNT_TYPES": "Account Types",
    "FOOTER": "Currency goes by",
    "FOOTER_CONTENT": "'Stakonomics' and Stakonomics's family of trademarks are owned by Stakonomics Corporation. All other trademarks appearing on this website are the property of their respective owners.",
    "TERMS_OF_SERVICE": "Terms Of Service",
    "PRIVACY_POLICY": "Privacy Policy",
    "COPY_RIGHTS": "Copyright © {{year}} <a href='/'>{{title}}</a> All Rights Reserved",
    "SILVER_ACCOUNT": "SILVER ACCOUNT",
    "MINI_DEP": "Minimum Deposit",
    "INCLUDES": "Includes",
    "CRYPTO_CURRENCIES": "Cryptocurrencies",
    "SAVING_ACCOUNT": "Saving Account",
    "DAILY_MARKET_VIEW": "Daily market review",
    "WEEKLY_MARKET_VIEW": "Weekly market review",
    "WEEKLY_WEBINAR": "Weekly live stream trading webinar",
    "DAILY_POR": "Daily portfolio progress report",
    "MONTHLY_SESSION": "Monthly session with a certified accountant",
    "FOREX_LEVERAGE": "up to {{leverage}} leverage for forex",
    "STOCKS_LEVERAGE": "{{leverage}} leverage for Stocks",
    "MARGIN_LOAN": "Up to {{percentage}} margin loan",
    "PERSONAL_PORFOLIO": "Personal portfolio manager",
    "ACCESS_TRADING_EVENTS": "Access to all special trading events",
    "DAILY_NEWS": "Daily news",
    "ACCOUNT_MANAGER": "Account manager",
    "WEEKLY_PORFOLIO": "Weekly portfolio progress report",
    "SELECT_SILVER": "Select Silver",
    "GOLD_ACCOUNT": "GOLD ACCOUNT",
    "INVITATION": "Invitation to special trading events",
    "SILVER_DESCRIPTION2": "Work with a portfolio manager",
    "24_HOUR": "24/7 account monitoring by top analyst group",
    "SELECT_GOLD": "Select Gold",
    "PLATINUM_ACCOUNT": "PLATINUM ACCOUNT",
    "WEEKLY_ANALYST": "Weekly 1 on 1 with market Analyst",
    "SELECT_PLATINUM": "Select Platinum",
    "DIAMOND_ACCOUNT": "DIAMOND ACCOUNT",
    "DAILY_ANALYST": "Daily one on one with top Analyst",
    "CUSTOMIZED_EDUCATION": "Customized Education",
    "DAILY_MARKET": "Daily market signals",
    "RESEARCH": "In-Depth Research",
    "INVITES_VIP": "Invites to VIP events",
    "SELECT_DIAMOND": "Select Diamond",
    "CORPORATE_ACCOUNT": "CORPORATE ACCOUNT",
    "DAILY_REVIEW": "Daily market review & signals",
    "UNLIMITED_ACCESS": "Unlimited access to brokers",
    "PERSONAL_CHIEF": "Personal chief portfolio manager",
    "DAILY_ONE": "Daily one on one live stream trading webinar with top Analyst",
    "WEALTH_MANAGER": "Wealth Manager",
    "SELECT_CORPORATE": "Select Corporate"

}
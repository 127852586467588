import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import config
import config from "../../config";

//import lib
import isEmpty from "../../lib/isEmpty";
import { useTheme } from "../ThemeContext";

const TradeviewChart = (props) => {
  const { theme, toggleTheme } = useTheme();
  // state
  // const [theme, setTheme] = useState(getTheme());
  // const [pair, setPair] = useState("BTCUSDT");
  var tvWidget = null;

  // console.log(props,'propspropspropspropsprops')

  // redux state
  const tradePair = useSelector((state) => state.tradePair);
  // const tradeThemeData = useSelector((state) => state.tradeTheme);
  // const themeData = useSelector((state) => state.theme);

  // function
  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair) => {
    try {
      let chartTheme = theme.toLowerCase()
      const widgetOptions = {
        symbol: pair,
        // BEWARE: no trailing slash is expected in feed URL
        interval: props.interval,
        container_id: props.containerId,
        allow_symbol_change: false,
        locale: getLanguageFromURL() || "en",
        client_id: props.clientId,
        user_id: props.userId,
        fullscreen: props.fullscreen,
        autosize: props.autosize,
        studies_overrides: props.studiesOverrides,

        theme: chartTheme,

      };

      if (theme == "White") {
        delete widgetOptions.toolbar_bg;
        delete widgetOptions.overrides;
      }

      const tvWidget = new window.TradingView.widget(widgetOptions);
      // tvWidget.onChartReady(() => {

      //   tvWidget.headerReady().then(() => {
      //     const button = tvWidget.createButton();
      //     button.setAttribute("title", "Click to show a notification popup");
      //     button.classList.add("apply-common-tooltip");
      //     button.addEventListener("click", () =>
      //       tvWidget.showNoticeDialog({
      //         title: "Notification",
      //         body: "TradingView Charting Library API works correctly",
      //         callback: () => {
      //           console.log("Noticed!");
      //         },
      //       })
      //     );

      //     // button.innerHTML = 'Check API';
      //   });
      // });
    } catch (err) {
      console.log(err, 'errror')
    }
  };

  useEffect(() => {
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(tradePair) && !isEmpty(theme)) {
      let symbol =
        tradePair.tikerRoot;
      // console.log("----themeData", symbol);
      let themeValue = theme

      // console.log("----sycmbol", symbol, themeValue);
      buildchart(themeValue, symbol);
    }
  }, [tradePair, theme]);


  return (
    <div id={props.containerId} />
  )
};

TradeviewChart.propTypes = {
  symbol: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  datafeedUrl: PropTypes.string.isRequired,
  libraryPath: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  fullscreen: PropTypes.string.isRequired,
  autosize: PropTypes.string.isRequired,
  studiesOverrides: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
};

TradeviewChart.defaultProps = {
  symbol: "BTCUSD",
  containerId: "tv_chart_container",
  // datafeedUrl: "https://api.coindcx.com/api/v1/chart/history_v3?symbol=BTCINR&resolution=1&from=1637539200&to=1637648173",
  chartsStorageUrl: "",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
};

export default TradeviewChart;

// import config
import axios, {
    setAuthorization,
    removeAuthorization,
    handleResp,
} from "../config/axios";
import config from "../config";
import { removeAuthToken, setAuthToken } from "../lib/localStorage";
import { toastAlert } from "../lib/toastAlert";

// import action
// import { setTradeTheme } from './commonAction';
// import { setTradeTheme as setTradeThemeLocal } from '../lib/localStorage'

// import constant
import {
    SET_USER_ACCOUNT,
    // SET_BANK_DETAIL,
    // SET_BANK_FORM,
    // SET_USER_SETTING,
    REMOVE_AUTHENTICATION,
    // UPDATE_USER_ACCOUNT,
    RESET_NOTICE,
    SET_UNREAD_NOTICE,
    SITE_SETTING,
    // UPDATE_2FA_STATUS,
    // SET_UPI_DETAIL,
    // SET_UPI_FORM,
    // SET_QR_FORM,
    // SET_QR_DETAIL
} from "../constant";

// import action
import { decodeJwt } from "./jsonWebToken";
import { getArbiProfit, getAssetData } from "./walletAction";
import { getRefLevList } from "./referralAction";

export const forceLogout = async (navigate, dispatch) => {
    let respData = await axios({
        method: "post",
        url: `/api/user/LogoutAll`,
    });

    dispatch({
        type: REMOVE_AUTHENTICATION,
        authData: {
            isAuth: false,
        },
    });
    dispatch({
        type: RESET_NOTICE,
    });
    removeAuthToken();
    removeAuthorization();

    // setTimeout(() => { navigate('/login') }, 1000)
};

export const createUser = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/register`,
            data,
        });

        if (respData?.data?.status === "OTP") {
            return {
                status: "OTP",
                loading: false,
                message: respData.data.message,
            };
        }

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/login`,
            data,
        });
        // console.log(respData?.data?.token,'respData?.data?.token')
        // if(respData?.data?.status === 'OTP') {
        //     return {
        //         status: 'OTP',
        //         loading: false,
        //         message: respData.data.message,
        //     }
        // }

        setAuthorization(respData?.data?.token);
        decodeJwt(respData?.data?.token, dispatch);
        setAuthToken(respData?.data?.token);
        dispatch(setAccountData(respData?.data?.result));
        getAssetData(dispatch);
        getArbiProfit(dispatch);

        // getRefLevList(dispatch)

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
            userSetting: respData.data.userSetting,
        };
        // }
    } catch (err) {
        handleResp(err, "error");

        try {
            return {
                status: "failed",
                loading: false,
                message: err.response.data.message,
                error: err.response.data.errors,
            };
        } catch (err) {
            handleResp(err, "error");
            return {
                status: "failed",
                loading: false,
            };
        }
    }
};

export const getGeoInfoData = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `${config.getGeoInfo}`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};

export const setAccountData = (data) => {
    return {
        type: SET_USER_ACCOUNT,
        data: {
            userId: data.userId,
            uniqueId: data.uniqueId,
            userCode: data.userCode,
            email: data.email,
            emailStatus: data.emailStatus,
            twoFAStatus: data.twoFAStatus,
            createAt: data.createAt,
            loginHistory: data.loginHistory,
            userName: data.userName,
            profile: data.profile,
            profitStatus: data.profitStatus,
            postalCode: data.postalCode,
            phoneNo: data.phoneNo,
            phoneCode: data.phoneCode,
            lastName: data.lastName,
            firstName: data.firstName,
            country: data.country,
            countryCode: data.countryCode,
            city: data.city,
            address: data.address,
            isTrade: data.isTrade,
            isStake: data.isStake,
            isExchange: data.isExchange,
            isLiveEvents: data.isLiveEvents,
            isHedge: data.isHedge,
            defaultcurrency: data.defaultcurrency,
            adminComment: data.adminComment,
            langCode: data.langCode,
        },
    };
};

export const logout = async (navigate, dispatch, t) => {
    let respData = await axios({
        method: "post",
        url: `/api/user/LogoutAll`,
    });

    dispatch({
        type: REMOVE_AUTHENTICATION,
        authData: {
            isAuth: false,
        },
    });
    dispatch({
        type: RESET_NOTICE,
    });
    removeAuthToken();
    removeAuthorization();
    toastAlert("success", t("LOGOUT_SUCCESS"), "logout");
    navigate("/login");

    // setTimeout(() => { navigate('/login') }, 1000)
};

export const emailVerify = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/confirm-mail`,
            data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/forgotPassword`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        };
    }
};

export const loginWithurl = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/loginWithurl`,
            data,
        });
        setAuthorization(respData?.data?.token);
        decodeJwt(respData?.data?.token, dispatch);
        setAuthToken(respData?.data?.token);
        dispatch(setAccountData(respData?.data?.result));
        getAssetData(dispatch);
        getArbiProfit(dispatch);

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const resetPasswordVerification = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/resetconfirmMail`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getCMSPage = async ({ identifier, langCode }) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/cms/${identifier}/${langCode}`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const resetPassword = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/resetPassword`,
            data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        };
    }
};

export const viewUserProfile = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/userProfile`,
        });
        dispatch(setAccountData(respData.data.result));
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const updateProfile = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/api/user/updateProfile`,
            data,
        });

        dispatch(setAccountData(respData.data.result));

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            errors: err?.response?.data?.errors,
        };
    }
};

export const getLoginHistory = async (params) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/loginHistory`,
            params,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
        };
    }
};

export const changePassword = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/changePassword`,
            data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        };
    }
};

export const UpdateLanguage = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/langCodeUpdate`,
            data,
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        };
    }
};

export const getFaq = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getFaq`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const getHomePage = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getHomepage`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
        };
    }
};

export const unReadNotice = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/unread-notice`,
        });

        dispatch({
            type: SET_UNREAD_NOTICE,
            notice: respData.data.result.NoticeData,
            notifiyCount: respData.data.result.notifiyCount,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const clearNotification = async (dispatch) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/api/user/unread-notice`,
        });

        dispatch({
            type: SET_UNREAD_NOTICE,
            notice: respData.data.result.NoticeData,
            notifiyCount: respData.data.result.notifiyCount,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        };
    }
};

export const FetchunReadNotice = async (dispatch, data) => {
    try {
        dispatch({
            type: SET_UNREAD_NOTICE,
            notice: data.NoticeData,
            notifiyCount: data.notifiyCount,
        });
    } catch (err) {}
};

export const getServerTime = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/serverTime`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
        };
    }
};

export const getSiteSetting = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getSiteSetting`,
        });

        dispatch({
            type: SITE_SETTING,
            data: respData.data.result,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
        };
    }
};

// export const verifyOtp = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/api/user/verifyOtp`,
//             data
//         });

//         return {
//             status: "success",
//             messages: respData.data.messages,
//             loading: false
//         }

//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const createUserPhone = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/api/user/registerphone`,
//             data
//         });

//         return {
//             loading: false
//         }

//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const userEmailActivation = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/confirm-mail`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors,
//             message : err.response.data.message
//         }
//     }
// }

// export const resendEmailVerify = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/resend-verify-mail`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const editUserProfile = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/userProfile`,
//             data
//         });
//         dispatch(setAccountData(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const showBtn = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/hide-btn`
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }

// export const updateAcctData = (data) => {
//     return {
//         type: UPDATE_USER_ACCOUNT,
//         data
//     }
// }

// export const checkTokenVerify = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/checkTokenVerify`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const forgotPin = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/forgotPin`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const resetPin = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/resetPin`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getBankDetail = async (dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/bankdetail`
//         });
//         dispatch(setBankDetail({
//             'result': respData.data.result
//         }))

//         return true
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return false
//     }
// }

// export const updateBankDetail = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/bankdetail`,
//             data
//         });

//         dispatch(setBankDetail({
//             'formType': '',
//             'formDisable': true,
//             'result': respData.data.result
//         }))

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const setPrimaryBank = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/user/bankdetail`,
//             data
//         });

//         dispatch(setBankDetail({
//             'result': respData.data.result
//         }))

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const deleteBankDetail = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/bankdetail`,
//             data
//         });

//         dispatch(setBankDetail({
//             'result': respData.data.result,
//             'formType': '',
//             'formDisable': true,
//             'editRecord': {}
//         }))

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const setBankDetail = (data) => {
//     return {
//         type: SET_BANK_DETAIL,
//         data
//     }
// }

// export const setBankForm = (data) => {
//     return {
//         type: SET_BANK_FORM,
//         data
//     }
// }

// export const get2faCode = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/security/2fa`
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// export const update2faCode = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/security/2fa`,
//             data
//         });
//         dispatch(update2FaStatus(respData.data.result.twoFaStatus))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const disabled2faCode = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/user/security/2fa`,
//             data
//         });
//         dispatch(update2FaStatus(respData.data.result.twoFaStatus))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const pinEnable = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/security/pin`,
//             data
//         });
//         dispatch(setAccountData(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const pinDisable = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/user/security/pin`,
//             data
//         });

//         dispatch(setAccountData(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getUserSetting = async (dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/userSetting`,
//         });
//         dispatch(setUserSetting(respData.data.result))
//         return true
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return false
//     }
// }

// export const setUserSetting = (data) => {
//     return {
//         type: SET_USER_SETTING,
//         data
//     }
// }
// export const update2FaStatus = (data) => {
//     return {
//         type: UPDATE_2FA_STATUS,
//         data
//     }
// }

// export const editUserSetting = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/userSetting`,
//             data
//         });

//         // setTradeThemeLocal(respData.data.result.theme)
//         dispatch(setUserSetting(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const upgradeUser = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/upgradeUser`,
//             data
//         });
//         dispatch(setAccountData(respData.data.result))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const changeNewPhone = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/phoneChange`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const verifyNewPhone = async (data,dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/phoneChange`,
//             data
//         });

//         dispatch(setAccountData(respData.data.result))

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const editEmail = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/emailChange`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const verifyOldEmail = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/emailChange`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// export const verifyNewEmail = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/user/emailChange`,
//             data
//         });

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// export const referralHist = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/referralHist`,
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }

// export const sentOTP = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/sentOTP`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     } catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// /* ********************** */
// export const verifyIpAddress = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/api/user/verifyIpAddress`,
//             data
//         });
//         await setAuthToken(respData.data.token)
//         // decodeJwt(respData.data.token, dispatch)
//         // await dispatch(setCurrentUser(respData.data.result))
//         // await setInitialCall();
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result,
//             messages: respData.data.messages
//         }

//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getCurrentUser = async (token, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `${config.API_URL}/api/user/currentUser`,
//             'headers': {
//                 'Authorization': token
//             },
//         });
//         // dispatch(setCurrentUser(respData.data.result))
//         return true
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return false
//     }
// }

// export const forgotPasswordMobile = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/api/user/forgotPassword/mobile`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             messages: respData.data.messages
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const editProfile = async (data) => {
//     try {
//         const formData = new FormData();
//         formData.append('firstName', data.firstName);
//         formData.append('lastName', data.lastName);
//         formData.append('profileImage', data.profileImage);

//         let respData = await axios({
//             'method': 'put',
//             'url': `${config.API_URL}/api/user/account/profile`,
//             'headers': {
//                 'content-type': 'multipart/form-data',
//                 'Authorization': localStorage.user_token
//             },
//             'data': formData
//         });

//         return {
//             status: "success",
//             loading: false
//         }

//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getUserDetail = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `${config.API_URL}/api/user/account/userProfile`,
//             'headers': {
//                 'Authorization': localStorage.user_token
//             }
//         });

//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const AddFavorite = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/api/user/add-fav`,
//             data
//         });
//         return {
//             status: "success",
//             loading: false,
//             messages: respData.data.messages,
//         }
//     } catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const GetFavorite = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `${config.API_URL}/api/user/get-fav`,

//         });
//         return {
//             status: "success",
//             loading: false,
//             messages: respData.data.messages,
//             result: respData.data.result
//         }
//     } catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getAllTrade = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/Statistic`
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// export const setPrimaryUPI = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/primaryupi`,
//             data
//         });
//         dispatch(setUPIDetail({
//             'result': respData.data.result
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         console.log("err---", err)
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const updateUPIDetail = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/upidetail`,
//             data
//         });
//         dispatch(setUPIDetail({
//             'formType': '',
//             'formDisable': false,
//             'result': respData.data.result
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const deleteUPIDetail = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/upidetail`,
//             data
//         });

//         dispatch(setUPIDetail({
//             'result': respData.data.result,
//             'formType': '',
//             'formDisable': true,
//             'editRecord': {}
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const getUPIDetail = async (dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/upidetail`
//         });
//         dispatch(setUPIDetail({
//             'result': respData.data.result.upiDetails
//         }))
//         return true
//     }
//     catch (err) {
//         return false
//     }
// }

// export const setUPIForm = (data) => {
//     return {
//         type: SET_UPI_FORM,
//         data
//     }
// }

// export const setUPIDetail = (data) => {
//     return {
//         type: SET_UPI_DETAIL,
//         data
//     }
// }

// export const setQRForm = (data) => {
//     return {
//         type: SET_QR_FORM,
//         data
//     }
// }

// export const setQRDetail = (data) => {
//     return {
//         type: SET_QR_DETAIL,
//         data
//     }
// }

// export const updateQRDetail = async (data, dispatch) => {
//     try {

//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/qrdetail`,
//             data
//         });
//         console.log("respData.data.result---", respData.data.result)

//         dispatch(setQRDetail({
//             'formType': '',
//             'formDisable': false,
//             'result': respData.data.result
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getQRDetail = async (dispatch) => {
//     try {

//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/qrdetail`
//         });

//         dispatch(setQRDetail({
//             'result': respData.data.result.qrDetails
//         }))

//         return true
//     }
//     catch (err) {
//         return false
//     }
// }

// export const setPrimaryQR = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/primaryqr`,
//             data
//         });

//         dispatch(setQRDetail({
//             'result': respData.data.result
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         console.log("err---", err)
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

// export const deleteQRDetail = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'put',
//             'url': `/api/user/qrdetail`,
//             data
//         });

//         dispatch(setQRDetail({
//             'result': respData.data.result,
//             'formType': '',
//             'formDisable': true,
//             'editRecord': {}
//         }))
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message
//         }
//     }
// }

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../config/index.js";
// Images
import { getCMSPage } from "../api/users.js";
import { getLang } from "../lib/localStorage.js";

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function Terms(props) {
    const { tittle } = useParams();

    const [content, setContent] = useState("");
    let langCode = getLang();
    // function
    const fetchCmsPage = async () => {
        try {
            const { status, loading, result } = await getCMSPage({
                identifier: tittle,
                langCode,
            });
            if (status == "success") {
                // console.log('result', result)
                result.content = result.content.replace(
                    /FRONTEND_URL/g,
                    `${config.FRONT_URL}/CMS_IMAGE`
                );
                setContent(result);
                document.title = result.title;
                const el = document.querySelector(
                    "meta[name='metadescription']"
                );
                el.setAttribute("content", result.metadescription);

                const els = document.querySelector("meta[name='keywords']");
                els.setAttribute("content", result.metakeywords);
            }
        } catch (err) {}
    };

    useEffect(() => {
        if (tittle) fetchCmsPage();
    }, [tittle]);

    console.log(content, "contentcontent");
    return (
        <div>
            <ScrollToTopOnMount />
            <Navbar />
            <div className="innerpages_wrapper">
                <div className="container">
                    {/* <div className="inner_title_wrapper">
            <h2 className="inner_title">Terms of Use</h2>
          </div> */}
                    <div className="dash_wrapper">
                        <div className="row">
                            <div className="col-xl-12">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: content && content.content,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* <NavbarFixed /> */}
        </div>
    );
}

export default {
    "HOMEPAGE_TITLE": "Freisetzen Sie Ihr Verdienstpotenzial: Maximieren Sie Ihre Renditen mit <span>{{stakonomics}}</span>",
    "TITLE_CONTENT": "Starten Sie noch heute Ihre Krypto-Staking-Reise und sehen Sie, wie Ihre Investitionen wachsen. Es ist Zeit, Ihren Anspruch in der Krypto-Revolution zu erheben!",
    "HOME_BTN1": "Jetzt Staken",
    "HOME_BTN2": "Jetzt Registrieren",
    "COUNT1": "Alle Teilnehmer",
    "COUNT2": "Gesamtverdienst",
    "COUNT3": "BTC 24H Änderung",
    "HEADER1": "Wie <span>{{stakonomics}}</span> funktioniert",
    "TITLE11": "Staken Sie Ihre Kryptowährung",
    "CONTENT11": "Wählen Sie eine Kryptowährung, die Staking unterstützt, erwerben Sie sie und übertragen Sie sie auf eine kompatible Staking-Plattform oder Wallet.",
    "TITLE12": "Teilnahme an Stakonomics",
    "CONTENT12": "Ihre gestakte Kryptowährung hilft, das Blockchain-Netzwerk zu sichern und seine Abläufe zu unterstützen. Ihre Beteiligung an Netzwerkaktivitäten bringt Ihnen Staking-Belohnungen ein, die auf der Größe Ihres Einsatzes und den Regeln des Netzwerks basieren.",
    "TITLE13": "Verwalten und Überwachen",
    "CONTENT13": "Überprüfen Sie regelmäßig Ihre Staking-Leistung, verfolgen Sie Ihre Belohnungen und bleiben Sie über Änderungen im Netzwerk informiert. Passen Sie Ihre Strategie nach Bedarf an, um das Beste aus Ihrer Staking-Erfahrung herauszuholen.",
    "HEADER2": "Verdienen Sie bis zu <span>{{stakonomics}}</span> auf Ihre Vermögenswerte",
    "TITLE21": "Passives Einkommen mit Staking",
    "CONTENT21": "Die Möglichkeiten des passiven Einkommens durch Krypto-Staking erkunden",
    "TITLE22": "Maximierung der Staking-Renditen",
    "CONTENT22": "Strategien zur Optimierung Ihrer Erträge im Krypto-Staking",
    "TITLE23": "Staking-Belohnungen verstehen",
    "CONTENT23": "Wie Staking im Kryptowährungsmarkt Gewinne bringt",
    "STAKECAL_TITLE": "Sehen Sie, was Sie verdienen können <span>{{Staking}}</span>",
    "ASSET": "Vermögenswert",
    "AMOUNT": "Betrag",
    "AMOUNT_PLACEHOLDER": "Betrag eingeben",
    "STAKING_METHOD": "Staking-Methode",
    "DEPOSIT_TERM": "Einzahlungsfrist",
    "FLEXIBLE": "Flexibel",
    "FIXED": "Fest",
    "STAKE_PERIOD": "In 12 Monaten werden Sie insgesamt",
    "IN": "In",
    "YOU_WILL": "werden Sie insgesamt haben",
    "FREQUENTLY_ASKED_QUESTIONS": "Häufig gestellte <span>{{Fragen}}</span>",
    "REGISTER": "Registrieren",
    "LOGIN": "Einloggen",
    "SIGN_UP": "Anmelden",
    "SIGN_IN": "Einloggen",
    "FIRST_NAME": "Vorname",
    "FIRST_NAME_PLACEHOLDER": "Vornamen eingeben",
    "FIRST_NAME_REQUIRED": "Vorname ist erforderlich",
    "LAST_NAME": "Nachname",
    "LAST_NAME_PLACEHOLDER": "Nachnamen eingeben",
    "LAST_NAME_REQUIRED": "Nachname ist erforderlich",
    "EMAIL": "E-Mail-Adresse",
    "EMAIL_PLACEHOLDER": "E-Mail-Adresse eingeben",
    "EMAIL_REQUIRED": "E-Mail ist erforderlich",
    "EMAIL_INVALID": "Ungültige E-Mail",
    "EMAIL_NOT_EXISTS": "E-Mail existiert nicht",
    "EMAIL_EXISTS": "E-Mail existiert bereits",
    "PHONE_NUMBER": "Telefonnummer",
    "PHONE_PLACEHOLDER": "Telefonnummer eingeben",
    "PHONE_NUMBER_REQUIRED": "Telefonnummer ist erforderlich",
    "PHONE_NUMBER_INVALID": "Ungültige Telefonnummer",
    "PHONE_NUMBER_EXISTS": "Telefonnummer existiert bereits",
    "PASSWORD": "Passwort",
    "PASSWORD_PLACEHOLDER": "Passwort eingeben",
    "PASSWORD_REQUIRED": "Passwort ist erforderlich",
    "PASSWORD_INCORRECT": "Falsches Passwort",
    "PASSWORD_MIN_MAX": "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl, ein Sonderzeichen enthalten und mindestens 6 und maximal 18 Zeichen lang sein",
    "CONFIRM_PASSWORD": "Passwort bestätigen",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Passwort bestätigen eingeben",
    "CONFIRM_PASSWORD_REQUIRED": "Bestätigung des Passworts ist erforderlich",
    "CONFIRM_PASSWORD_MISMATCH": "Passwort und Bestätigungspasswort müssen übereinstimmen",
    "TERMS_REQUIRED": "Feld für Geschäftsbedingungen und Richtlinien ist erforderlich",
    "ALREADY_HAVE_ACCOUNT": "Haben Sie bereits ein Konto?",
    "DON'T_HAVE_ACCOUNT": "Haben Sie noch kein Konto?",
    "REGISTER_CHECKBOX": "Ich verstehe <Link to='/terms'>{{value1}}</Link> und <Link1 to='/privacy'>{{value2}}</Link1>",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "OTP eingeben",
    "RESENT_OTP": "Verifizierungscode erneut senden",
    "OTP_VALIDUPTO": "OTP gültig bis",
    "OTP_REQUIRED": "OTP-Feld ist erforderlich",
    "OTPSTATUS_REQUIRED": "OTP-Status ist erforderlich",
    "INVALID_OTPSTATUS": "Ungültiger OTP-Status",
    "OTP_SENT_SUCCESS": "OTP wurde an Ihre E-Mail-Adresse gesendet, bitte geben Sie den OTP ein",
    "EXPIRED_OTP": "OTP abgelaufen",
    "INVALID_OTP": "Ungültiger OTP",
    "SEC": "Sek.",
    "REGISTER_SUCCESS": "Ihr Konto wurde erfolgreich registriert. Vielen Dank!",
    "SOMETHING_WRONG": "Etwas ist schiefgelaufen",
    "ERROR_ON_SERVER": "Fehler auf dem Server",
    "ERROR_OCCURED": "Ein Fehler ist aufgetreten",
    "EMAIL_UNVERIFIED": "Ihr Konto wurde noch nicht aktiviert",
    "ACCOUNT_DEACTIVATE": "Ihr Konto wurde vom Administrator deaktiviert, bitte kontaktieren Sie den Administrator",
    "LOGIN_SUCCESS": "Erfolgreich eingeloggt",
    "LOGOUT_SUCCESS": "Erfolgreich ausgeloggt!",
    "FORGOT_PASSWORD": "Passwort vergessen",
    "FORGOT_PASSWORD_CONTENT": "Der Link zum Zurücksetzen des Passworts wurde an Ihre registrierte E-Mail-Adresse gesendet",
    "FORGOT_SUCCESS": "Der Bestätigungslink wurde an Ihre registrierte E-Mail gesendet",
    "AUTH_TOKEN_REQUIRED": "Auth-Token-Feld ist erforderlich",
    "INVALID_TOKEN": "Ungültiges Token",
    "RESET_PASSWORD": "Passwort zurücksetzen",
    "NEW_PASSWORD": "Neues Passwort",
    "NEW_PASSWORD_PLACEHOLDER": "Neues Passwort eingeben",
    "NEW_PASSWORD_REQUIRED": "Feld für neues Passwort ist erforderlich",
    "NEW_CONFIRM_MISMATCH": "Neues Passwort und Bestätigungspasswort müssen übereinstimmen",
    "LING_EXPIRED": "Ihr Link ist abgelaufen",
    "INVALID_LINK": "Ungültiger Link",
    "USER_NOT_FOUND": "Benutzer nicht gefunden",
    "VERIFICATION_SUCCESS": "Ihr Verifizierungsprozess wurde abgeschlossen, Sie können nun Ihr Passwort ändern",
    "RESET_PASSWORD_SUCCESS": "Passwort erfolgreich aktualisiert",
    "CHANGE_PASSWORD": "Passwort ändern",
    "OLD_PASSWORD": "Altes Passwort",
    "OLD_PASSWORD_PLACEHOLDER": "Altes Passwort eingeben",
    "OLD_PASSWORD_REQUIRED": "Das Feld Altes Passwort ist erforderlich",
    "INCORRECT_OLDPASSWORD": "Falsches altes Passwort",
    "PASSWORD_SAME": "Das neue Passwort darf nicht mit dem alten Passwort übereinstimmen",
    "CHANGE_PASSWORD_SUCCESS": "Passwort erfolgreich geändert",
    "CHANGE": "Ändern",
    "LAST_LOGIN": "Letzte Anmeldung",
    "EDIT_PROFILE": "Profil bearbeiten",
    "UPDATE_PROFILE": "Profil aktualisieren",
    "PROFILE": "Profilbild (Optional)",
    "INVALID_PROFILE": "Ungültiges Profilformat",
    "TOO_LARGE": "Zu groß",
    "PROFILE_RESTRICTION": "Maximale Upload-Dateigröße 2MB (nur Jpeg, Png, Jpg).",
    "ADDRESS": "Adresse",
    "ADDRESS_PLACEHOLDER": "Adresse eingeben",
    "ADDRESS_REQUIRED": "Das Feld Adresse ist erforderlich",
    "CITY": "Stadt",
    "CITY_PLACEHOLDER": "Stadt eingeben",
    "CITY_REQUIRED": "Das Feld Stadt ist erforderlich",
    "POSTAL_CODE": "Postleitzahl",
    "POSTAL_CODE_PLACEHOLDER": "Postleitzahl eingeben",
    "POSTAL_CODE_REQUIRED": "Das Feld Postleitzahl ist erforderlich",
    "COUNTRY": "Land",
    "COUNTRY_PLACEHOLDER": "Land eingeben",
    "COUNTRY_REQUIRED": "Das Feld Land ist erforderlich",
    "UPLOAD_IMAGE": "Hochgeladene Datei darf nicht größer als 2MB sein!",
    "CANCEL": "Abbrechen",
    "UPDATE": "Aktualisieren",
    "SUBMIT": "Absenden",
    "PROFILE_SUCCESS": "Profil erfolgreich aktualisiert",
    "LOGIN_ACTIVITY": "Anmeldeaktivität",
    "DATE": "Datum",
    "BROWSER": "Browser",
    "STATUS": "Status",
    "FAILED": "Fehlgeschlagen",
    "SUCCESS": "Erfolgreich",
    "MY_ACCOUNT": "Mein Konto",
    "ACCOUNT_SETTINGS": "Kontoeinstellungen",
    "LOGOUT": "Abmelden",
    "NOTIFICATION": "Benachrichtigungen",
    "NO_NOTIFICATION": "Keine Benachrichtigungen!",
    "UPDATE_SUCCESS": "Erfolgreich aktualisiert",

    "SUPPORT_TICKET": "Support-Ticket",
    "SUBMIT_REQUEST": "Anfrage senden",
    "TICKET_FOR": "Ticket für",
    "SELECT_CATEGORY": "Kategorie auswählen",
    "DESCRIPTION": "Beschreibung",
    "ENTER_MESSAGE": "Nachricht eingeben",
    "SUPPORT_DESCRIPTION": "Bitte geben Sie die Details Ihrer Anfrage ein. Ein Mitglied unseres Support-Teams wird so schnell wie möglich antworten.",
    "REQUIRED": "Erforderlich",
    "DOCUMENT_UPLOAD": "Dokument hochladen (Optional)",
    "UPLOAD": "Hochladen",
    "UPLOAD_SIZE_RESTRICT": "Maximale Upload-Dateigröße 1MB (nur Jpeg, Png, Pdf, Jpg).",
    "UPLOAD_ERROR": "Die hochgeladene Datei darf nicht größer als 1MB sein!",
    "MESSAGE_REQUIRED": "Das Feld Nachricht ist erforderlich",
    "SUBJECT_REQUIRED": "Das Feld Betreff ist erforderlich",
    "UPLOAD_ERROR2": "Bitte wählen Sie eine Datei mit einem bekannten Dateityp: jpg, jpeg, png, pdf oder mp4.",
    "TICKET_RAISED_SUCCESS": "Support-Ticket erfolgreich erstellt",
    "TICKET_ALREADY_CLOSED": "Ticket bereits geschlossen",
    "MESSAGE_SENT_SUCCESS": "Ihre Nachricht wurde erfolgreich gesendet",
    "SUPPORT_TICKET_DETAILS": "Support-Ticket-Details",
    "TICKET_CLOSED_SUCCESS": "Ticket erfolgreich geschlossen",
    "TICKET_ID": "Ticket-ID",
    "CREATES_ON": "Erstellt am",
    "VIEW": "Datei anzeigen",
    "SUPPORT_TEAM": "Support-Team",
    "OPEN": "Offen",
    "CLOSED": "Geschlossen",
    "ALL": "Alle",
    "SEARCH": "Suche",
    "CLOSE_TICKET": "Ticket schließen",
    "SUB": "Unter",
    "TICKET_NOT_FOUND": "Keine Tickets gefunden",

   "WALLET": "Brieftasche",
    "TOTAL_BALANCE": "Gesamtguthaben",
    "BALANCE": "Guthaben",
    "ACTION": "Aktion",
    "DEPOSIT": "Einzahlung",
    "WITHDRAW": "Auszahlung",
    "EXCHANGE": "Börse",
    "EXCHANGE_ACCESS": "Börsenzugang blockiert",
    "STAKE": "Staken",
    "STAKE_ACCESS": "Staking-Zugang blockiert",
    "TRADE_ACCESS": "Handelszugang blockiert",
    "LIVE_EVENT_ACCESS": "Live-Event-Zugang blockiert",
    "HEDGE_ACCESS": "Hedgefonds-Zugang blockiert",
    "HIDE_ZERO_BALANCES": "Nullguthaben ausblenden",



    "DEPOSIT_TERMS": "Einzahlungsbedingungen",
    "MINIMUM_DEPOSIT_AMOUNT": "Mindestbetrag für Einzahlung: ",
    "INVALID_IMAGE": "Ungültiges Bild",
    "MAINTENANCE_ERROR": "Wartung läuft, bitte versuchen Sie es später erneut!",
    "DEPOSIT_DESCRIPTION": "Einzahlungen unter dem Mindestbetrag werden nicht akzeptiert und Rückerstattungen sind nicht möglich",
    "DEPOSIT_NOTES1": "Senden Sie nur {{CURRENCY_SYMBOL}} an diese Wallet-Adresse.",
    "DEPOSIT_NOTES2": "Das Senden von Coins oder Token, die nicht {{CURRENCY_SYMBOL}} sind, an diese Wallet kann zum vollständigen Verlust Ihrer Einzahlung führen",
    "DEPOSIT_NOTES3": "Bitte laden Sie einen Screenshot oder eine Transaktions-ID hoch, damit wir Ihre Transaktion genehmigen können",
    "DEPOSIT_CARD": "Einzahlung mit Karte",
    "DEPOSIT_ADDRESS": "Einzahlungsadresse",
    "MEMO_ADDRESS": "Memo-Adresse",
    "DEPOSIT_IMAGE_RESTRICTION": "Maximale Upload-Dateigröße 2MB (nur Jpg, Jpeg, Png, Pdf).",
    "DEPOSIT_AMOUNT_ERROR": "Bitte geben Sie einen Einzahlungsbetrag ein, der größer oder gleich {{AMOUNT}} ist",
    "DEPOSIT_SUCCESS": "Einzahlungsanfrage erfolgreich gesendet",
    "ASSET_ERROR": "Das Benutzer-Asset-Feld ist erforderlich",
    "COPIED": "Kopiert!",

    "WITHDRAW_TERMS": "Auszahlungsbedingungen",
    "MINIMUM_WITHDRAW_AMOUNT": "Der Mindestbetrag für Auszahlungen beträgt: ",
    "TERMS1": "Maximale Auszahlung:",
    "TERMS2": "Auszahlungsgebühr:",
    "RECEIVER_ADDRESS": "Empfängeradresse",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Bitte geben Sie hier die Auszahlungsadresse ein",
    "RECEIVER_ADDRESS_REQUIRED": "Das Feld Empfängeradresse ist erforderlich",
    "WITHDRAW_AMOUNT": "Auszahlungsbetrag",
    "WITHDRAW_AMOUNT_ERROR": "Bitte geben Sie einen Auszahlungsbetrag ein, der größer oder gleich {{AMOUNT}} ist",
    "MINIMUM_WITHDRAW_ERROR": "Der Mindestauszahlungsbetrag muss größer oder gleich {{AMOUNT}} sein",
    "WITHDRAW_MAXAMOUNT_ERROR": "Der Auszahlungsbetrag muss kleiner oder gleich {{AMOUNT}} sein",
    "WITHDRAW_SUCCESS": "Auszahlungsanfrage erfolgreich gesendet",
    "INSUFFICIENT_WITHDRAW": "Auszahlungsbetrag zu niedrig",
    "WITHDRAW_OTP": "Ein OTP wurde erfolgreich an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihr Postfach und verwenden Sie den bereitgestellten Code, um fortzufahren",

    "FROM_CURRENCY": "Von Währung",
    "TO_CURRENCY": "Zu Währung",
    "TO_CURRENCY_REQUIRED": "Das Feld Zielwährung ist erforderlich",
    "SELECT_CURRENCY": "Währung auswählen",
    "INVALID_USER": "Ungültiger Benutzer",
    "INSUFFICIENT_BALANCE": "Unzureichendes Guthaben",
    "TRANSFER_SUCCESS": "Erfolgreich übertragen",

    "TRANSACTION": "Transaktionen",
    "DATE": "Datum",
    "COIN": "Münze",
    "USER_ID": "Benutzer-ID",
    "TYPE": "Typ",
    "REASON": "Grund",
    "PENDING": "Ausstehend",
    "COMPLETED": "Abgeschlossen",
    "REJECTED": "Abgelehnt",
    "COIN_DEPOSIT": "Münz-Einzahlung",
    "COIN_WITHDRAW": "Münz-Auszahlung",
    "COIN_TRANSFER": "Münz-Übertragung",
    "PROFIT": "Gewinn",
    "ROWS_PER_PAGE": "Zeilen pro Seite",
    "OF": "von",

    "STAKING": "Staking",
    "AVAILABLE_BALANCE": "Verfügbares Guthaben",
    "AVAILABLE_BALANCE_USDT": "Verfügbares Guthaben USDT",
    "APY": "APY",
    "MAX": "Max",
    "INTEREST": "Zinsen",
    "MINIMUM_STAKE": "Mindestbetrag für Staking",
    "MAXIMUM_STAKE": "Maximaler Einsatz",
    "WITHDRAWAL_TERM": "Auszahlungsfrist",
    "REDEMPTION_PERIOD": "Einlösungszeitraum",
    "DURATION": "Dauer (Tage)",
    "CONFIRM": "Bestätigen",
    "STAKING_PERIOD": "Staking-Periode",
    "LOCKED_FOR": "Gesperrt für",
    "AMOUNT_REQUIRED": "Betragsfeld ist erforderlich",
    "VALID_AMOUNT": "Bitte geben Sie einen gültigen Betrag ein",
    "AMOUNT_CAN'T_ZERO": "Betrag muss größer als null sein",
    "MINIMUM_AMOUNT": "Der Mindestbetrag muss größer oder gleich {{AMOUNT}} sein",
    "MAXIMUM_AMOUNT": "Betrag muss kleiner oder gleich {{AMOUNT}} sein",
    "INVALID_STAKING": "Ungültiges Staking",
    "INVALID_CURRENCY": "Ungültige Währung",
    "INVALID_ASSET": "Ungültiges Asset",
    "DEACTIVE": "Deaktiviert",
    "NO_RECORD": "Kein Eintrag",
    "INSUFFICIENT_ASSET": "Nicht genügend Vermögen auf Ihrem Konto",
    "STAKING_SUCCESS": "Staking-Auftrag erfolgreich hinzugefügt",
    "DURATION_ERROR": "Bitte wählen Sie die Dauer in Tagen",
    "ALREADY_CANCELED": "Bereits storniert",
    "STAKING_HISTORY": "Staking-Verlauf",
    "TOTAL_EARNED_REDEMPTION": "Gesamteingelöste Erträge",
    "INTEREST_PAID": "Gezahlte Zinsen",
    "ESTIMATED_APY_INTEREST": "Geschätzte APY-Zinsen",
    "ESTIMATED_TOTAL_INTEREST": "Geschätzte Gesamtzinsen",
    "ESTIMATED_TOTAL_REDEMPTION": "Geschätzte Gesamtrücknahme",
    "REMAINING_DAYS": "Verbleibende Tage",
    "RELEASE": "Freigeben",
    "INTEREST_EARNED": "Erhaltene Zinsen",
    "INTEREST_IN": "Zinsen in ($)",
    "STAKE_TYPE": "Staking-Typ",
    "SUBSCRIPTION": "Abonnement",
    "REDEMPTION_HISTORY": "Einlösungsverlauf",
    "THERE_NO_RECORD": "Keine Einträge zum Anzeigen",
    "RELEASE_STAKE": "Sind Sie sicher, dass Sie diese Bestellung stornieren möchten?",
    "OK": "OK",
    "RELEASE_SUCCESS": "Erfolgreich freigegeben",

    "TRADE": "Handel",
    "PRO_TRADE": "PRO-HANDEL",
    "BASIC": "Grundlegend",
    "LOADING": "Laden",
    "PNL": "PNL",
    "CASH_BALANCE": "Barguthaben",
    "EQUITY": "Eigenkapital",
    "USED_MARGIN": "Verwendete Margin",
    "AVAILABLE_MARGIN": "Verfügbare Margin",
    "CRYPTO": "Krypto",
    "CURRENCIES": "Währungen",
    "STOCKS": "Aktien",
    "INDICES": "Indizes",
    "COMMODITIES": "Rohstoffe",
    "BUY": "Kaufen",
    "SELL": "Verkaufen",
    "QUANTITY": "Menge",
    "QUANTITY_PLACEHOLDER": "Menge eingeben",
    "QUANTITY_REQUIRED": "Mengenfeld ist erforderlich",
    "QUANTITY_ERROR": "Menge darf nur numerische Werte enthalten",
    "QUANTITY_GRATER": "Menge muss größer als null sein",
    "SIDE_REQUIRED": "Seitenfeld ist erforderlich",
    "INVALID_SIDE": "Ungültige Seite",
    "PAIR_REQUIRED": "Paarfeld ist erforderlich",
    "TRADE_SIZE": "Handelsgröße",
    "PIP_VALUE": "Pip-Wert",
    "ORDER_BOOK": "Auftragsbuch",
    "OPEN_POSITIONS": "Offene Positionen",
    "CLOSED_POSITIONS": "Geschlossene Positionen",
    "CLOSE_ALL_TRADES": "Alle Trades schließen",
    "PAIR_NAME": "Paarname",
    "BUYSELL": "Kaufen/Verkaufen",
    "ENTRY_PRICE": "Einstiegspreis",
    "OPEN_TIME": "Öffnungszeit",
    "CLOSE_PRICE": "Schlusspreis",
    "CLOSE_TIME": "Schlusszeit",
    "CURRENT_PRICE": "Aktueller Preis",
    "LEVERAGE": "Hebelwirkung",
    "PNL_CLOSE": "PNL/Schließen",
    "TP": "TP",
    "TAKE_PROFIT": "Take Profit",
    "STOP_LOSS": "Stop Loss",
    "SL": "SL",
    "TP_PRICE": "Take Profit bei Preis",
    "SP_PRICE": "Stop Loss bei Preis",
    "TAKE_PROFIT_PLACEHOLDER": "Take Profit eingeben",
    "STOP_LOSS_PLACEHOLDER": "Stop Loss eingeben",
    "TAKE_PROFIT_REQUIRED": "Take Profit-Feld ist erforderlich",
    "TAKE_PROFIT_GREATER": "Take Profit muss höher als der Marktpreis sein",
    "TAKE_PROFIT_LESSER": "Take Profit muss niedriger als der Marktpreis sein",
    "STOP_LOSS_REQUIRED": "Stop Loss-Feld ist erforderlich",
    "STOP_LOSS_GREATER": "Stop Loss muss niedriger als der Marktpreis sein",
    "STOP_LOSS_LESSER": "Stop Loss muss höher als der Marktpreis sein",
    "POSITION_NOT_FOUND": "Position nicht gefunden",
    "TAKE_PROFIT_SUCCESS": "Take Profit erfolgreich aktualisiert",
    "STOP_LOSS_SUCCESS": "Stop Loss erfolgreich aktualisiert",
    "CLOSED_USER": "Vom Benutzer geschlossen",
    "LOW_MARGIN": "Geringe Margin",
    "CLOSE_ORDER": "Auftrag schließen",
    "CLOSE_CONFIRM": "Sind Sie sicher, dass Sie diese Bestellung schließen möchten?",
    "TRADE_ALREADY_CLOSED": "Ihre Bestellung wurde bereits geschlossen",
    "QUANTITY_MINIMUM_ERROR": "Die Menge des Vertrags darf nicht geringer als {{AMOUNT}} sein",
    "QUANTITY_MAXIMUM_ERROR": "Die Menge des Vertrags darf nicht größer als {{AMOUNT}} sein",
    "INSUFFICIENT_TRADE_BALANCE": "Aufgrund unzureichenden Guthabens kann die Bestellung nicht aufgegeben werden",
    "ORDER_PLACE_SUCCESS": "Ihre Bestellung wurde erfolgreich aufgegeben",
    "NO_DATA": "Keine Daten",
    "ORDER_CLOSE_SUCCESS": "Ihre Bestellung wurde erfolgreich geschlossen",
    "CLOSED_BY_USER": "Vom Benutzer geschlossen",

    "LIVE_EVENTS": "Live-Events",
    "NO_LIVE_EVENTS": "Keine Live-Events zum Anzeigen",
    "LIVE_ERROR": "Ihr Browser unterstützt das Video-Tag nicht",

    "HEDGE_FUND": "Hedgefonds",
    "TOTAL_HEDGE_FUND": "Gesamtwert des Hedgefonds-Portfolios",
    "PORTFOLIO_PERFORMANCE": "Portfolio-Leistung",
    "PORTFOLIO_ALLOCATION": "Portfolio-Allokation",
    "NAME": "Name",
    "PRICE": "Preis",
    "24_CHANGE": "24H Veränderung",
    "24_HIGH": "24H Hoch",
    "24_LOW": "24H Tief",
    "TOTAL": "Gesamt",
    "DAY": "Tag",
    "DAYS": "Tage",
    "WEEK": "Woche",
    "MONTH": "Monat",
    "YEAR": "Jahr",

    "STAKED_BALANCE": "Gestaktes Guthaben",
    "ACCOUNT_BALANCE": "Kontostand",
    "TOTAL_PL": "Gesamtergebnis",

    "INSTRUMENTS": "Instrumente",
    "FOREX": "Forex",
    "TRADING": "Handel",
    "PLATFORM": "Plattform",
    "OPEN_ACCOUNT": "Konto eröffnen",
    "MT5_PLATFORM": "MT5-Plattform",
    "WEBTRADER_PLATFORM": "Webtrader-Plattform",
    "MOBILE_PLATFORM": "Mobile Plattform",
    "FX_PAYMENTS": "FX-Zahlungen",
    "ACCOUNT_TYPES": "Kontotypen",
    "FOOTER": "Währung geht nach",
    "FOOTER_CONTENT": "'Stakonomics' und die Stakonomics-Markenfamilie sind Eigentum der Stakonomics Corporation. Alle anderen Marken, die auf dieser Website erscheinen, sind Eigentum ihrer jeweiligen Besitzer.",
    "TERMS_OF_SERVICE": "Nutzungsbedingungen",
    "PRIVACY_POLICY": "Datenschutzrichtlinie",
    "COPY_RIGHTS": "Urheberrecht © {{year}} <a href='/'>{{title}}</a> Alle Rechte vorbehalten",
    "SILVER_ACCOUNT": "SILBER-KONTO",
    "MINI_DEP": "Mindesteinzahlung",
    "INCLUDES": "Beinhaltet",
    "CRYPTO_CURRENCIES": "Kryptowährungen",
    "SAVING_ACCOUNT": "Sparkonto",
    "DAILY_MARKET_VIEW": "Tägliche Marktübersicht",
    "WEEKLY_MARKET_VIEW": "Wöchentliche Marktübersicht",
    "WEEKLY_WEBINAR": "Wöchentliches Live-Trading-Webinar",
    "DAILY_POR": "Täglicher Portfolio-Fortschrittsbericht",
    "MONTHLY_SESSION": "Monatliche Sitzung mit einem zertifizierten Buchhalter",
    "FOREX_LEVERAGE": "Bis zu {{leverage}} Hebel für Forex",
    "STOCKS_LEVERAGE": "{{leverage}} Hebel für Aktien",
    "MARGIN_LOAN": "Bis zu {{percentage}} Margin-Darlehen",
    "PERSONAL_PORFOLIO": "Persönlicher Portfoliomanager",
    "ACCESS_TRADING_EVENTS": "Zugang zu allen speziellen Trading-Events",
    "DAILY_NEWS": "Tägliche Nachrichten",
    "ACCOUNT_MANAGER": "Kundenbetreuer",
    "WEEKLY_PORFOLIO": "Wöchentlicher Portfolio-Fortschrittsbericht",
    "SELECT_SILVER": "Silber auswählen",
    "GOLD_ACCOUNT": "GOLD-KONTO",
    "INVITATION": "Einladung zu speziellen Trading-Events",
    "SILVER_DESCRIPTION2": "Zusammenarbeit mit einem Portfoliomanager",
    "24_HOUR": "24/7 Kontenüberwachung durch führende Analystengruppe",
    "SELECT_GOLD": "Gold auswählen",
    "PLATINUM_ACCOUNT": "PLATIN-KONTO",
    "WEEKLY_ANALYST": "Wöchentliches Einzelgespräch mit einem Marktanalysten",
    "SELECT_PLATINUM": "Platin auswählen",
    "DIAMOND_ACCOUNT": "DIAMANT-KONTO",
    "DAILY_ANALYST": "Tägliches Einzelgespräch mit einem Top-Analysten",
    "CUSTOMIZED_EDUCATION": "Individuelle Weiterbildung",
    "DAILY_MARKET": "Tägliche Marktsignale",
    "RESEARCH": "Detaillierte Marktanalyse",
    "INVITES_VIP": "Einladungen zu VIP-Events",
    "SELECT_DIAMOND": "Diamant auswählen",
    "CORPORATE_ACCOUNT": "UNTERNEHMENSKONTO",
    "DAILY_REVIEW": "Tägliche Marktanalyse & Signale",
    "UNLIMITED_ACCESS": "Unbegrenzter Zugang zu Brokern",
    "PERSONAL_CHIEF": "Persönlicher leitender Portfoliomanager",
    "DAILY_ONE": "Tägliches Einzelgespräch per Live-Webinar mit einem Top-Analysten",
    "WEALTH_MANAGER": "Vermögensverwalter",
    "SELECT_CORPORATE": "Unternehmenskonto auswählen"

}